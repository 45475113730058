var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Notifications "),_c('v-spacer')],1),_c('data-table',{attrs:{"items":_vm.rows,"headers":_vm.headers,"totalItems":_vm.rows.length,"loading":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var props = ref.props;
var headers = ref.headers;
return [(headers.value == 'action')?_c('span',[_c('TextButton',{attrs:{"btnColor":"error","btnText":"De-activate"},on:{"callAction":function($event){return _vm.deActivateNotification(props)}}})],1):_vm._e(),(headers.value == 'type')?_c('span',[_vm._v(" "+_vm._s((props[headers.value] == "" ? headers.default : props[headers.value]) == 1 ? "Feed" : "Banner")+" ")]):(headers.value == 'image')?_c('span',[_c('a',{attrs:{"target":"_blank","href":props[headers.value] == ''
                ? headers.default
                : props[headers.value]}},[_vm._v(" "+_vm._s(props[headers.value] == "" ? headers.default : props[headers.value])+" ")])]):_c('span',{class:props[headers.value] == '' ? "blackLow--text" : ""},[_vm._v(" "+_vm._s(props[headers.value] == "" ? headers.default : props[headers.value])+" ")])]}}])})],1),_c('v-snackbar',{attrs:{"color":"success"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" Notification Deleted ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }