







































































import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import TextButton from "@/components/common/Buttons/TextButton.vue";
import DataTable from "@/components/common/Tables/DataTable.vue";
import NotificationService from "@/services/NotificationService";
import { INotification } from "@/interfaces/dmsnotification";
import { ITableHeader } from "@/interfaces/common";
import { component } from "vue/types/umd";
@Component({
  components: {
    DataTable,
    TextButton,
  },
})
export default class List extends Vue {
  private rows: Array<INotification> = [];
  private headers: Array<ITableHeader> = [
    {
      text: "Action",
      value: "action",
      sortable: false,
      default: "",
    },
    {
      text: "Type",
      value: "type",
      sortable: false,
      default: "",
    },
    {
      text: "Title",
      value: "title",
      sortable: false,
      default: "",
    },
    {
      text: "Text",
      value: "text",
      sortable: false,
      default: "",
    },
    {
      text: "Image",
      value: "image",
      sortable: false,
      default: "",
    },
  ];
  private totalItems: number = 0;
  private loading: boolean = true;
  private snackbar: boolean = false;
  public async getNotificationList(): Promise<any> {
    NotificationService.getDMSNotification().then((response) => {
      this.rows = response.data.notifications;
      this.totalItems = response.data.notifications.length;
      this.loading = false;
    });
  }
  public async deActivateNotification(params: any): Promise<any> {
    NotificationService.deactivateNotification(params.id).then((response) => {
      this.snackbar = true;
      this.getNotificationList();
    });
  }

  async mounted() {
    this.getNotificationList();
  }
}
